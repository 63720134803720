<template>
  <div>
    <vuestic-widget :loading="loading || loadingDiscount" class="zapier-page">
      <template slot="header">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <span class="title">Zapier Integration</span>
          <div class="d-flex align-items-start justify-content-between">
          </div>
        </div>
      </template>
      <div>
        <div v-if="plan" class="row">
          <div class="col-md-12">
            <h5 class="font-weight-bold mb-4">Features</h5>
            <h6 class="font-weight-bold">Triggers</h6>
            <ul>
              <li>Phone Call Received</li>
              <li>Text Message Received</li>
            </ul>
            <h6 class="font-weight-bold">Actions</h6>
            <ul>
              <li>Create or Update Contact</li>
              <li>Initiate a Call</li>
              <li>Send a New Text Message</li>
              <li>Send a New Text Message & Initiate a Call</li>
            </ul>
          </div>
          <div class="col-md-12 mt-2">
            <h6 class="font-weight-bold">Price</h6>
            <div v-if="plan.trial_period_days">
              <span class="text-primary font-italic font-weight-bold">{{plan.trial_period_days}} Days Free Trial </span> then
            </div>
            <div class="text-primary font-weight-bold">
              <div class="mr-3">
                <p class="d-inline-block mb-0" :style="{'text-decoration': discount ? 'line-through' : 'auto'}">${{ plan.amount }} / <span class="text-capitalize">{{ plan.interval }}</span></p>
                <p v-if="discount" class="d-inline-block ml-2 mb-0">${{ discountPrice }} / <span class="text-capitalize">{{ plan.interval }}</span></p>
              </div>
            </div>
            <template v-if="showDiscountInput">
              <div class="font-weight-bold mt-2" style="color: #0d9d58; text-decoration: underline;">
                <span class="">Use Discount Code zap2021 for $50 Off Monthly (100 available only 37 left)</span>
              </div>
              <div style="max-width: 400px;" class="d-flex align-items-start justify-content-start mt-2">
                <text-input name="DiscountCode" v-model="couponCode" placeholder="Discount Code" style="min-width: 150px"/>
                <button class="btn btn-success ml-2" @click="onApplyCoupon" :disabled="couponProcessing || !couponCode" style="color: #fff!important;min-width: 150px">
                  <atom-spinner v-if="couponProcessing" slot="loading" :animation-duration="1500" :size="20" color="#FFF" style="margin: auto"/>
                  <span v-else>Apply</span>
                </button>
              </div>
            </template>
          </div>
          <div class="col-md-12 mt-2" v-if="subscription && subscription.active">
            <a class="text-primary text-capitalize font-italic font-weight-bold" :href="zapInviteURL" target="_blank">Click here to integrate Zapier app to your account.</a>
          </div>
          <div class="col-md-12 mt-4">
            <template v-if="subscription && subscription.active && subscription.grace_plan">
              <p class="text-danger font-italic">Cancels at {{ subscription.ends_at | shorttime }}</p>
              <button class="btn btn-primary" @click="onResume" :disabled="processing" style="min-width: 250px">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="20" color="#FFF" style="margin: auto"/>
                <span v-else>Resume Subscription</span>
              </button>
            </template>

            <!-- <template v-if="subscription && subscription.active && !subscription.grace_plan">
              <button class="btn btn-danger" @click="onCancel" :disabled="processing" style="min-width: 250px">
                <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="20" color="#FFF" style="margin: auto"/>
                <span v-else>Cancel Subscription</span>
              </button>
            </template> -->

            <button v-if="!subscription || !subscription.active" class="btn btn-primary" :disabled="processing" @click="onSubmit" style="min-width: 250px">
              <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="20" color="#FFF" style="margin: auto"/>
              <span v-else>Start your integration NOW</span>
            </button>
          </div>
        </div>
        <template v-else>
          <p>No plans available</p>
        </template>
      </div>
    </vuestic-widget>
    <vuestic-modal
      :isOpen="isOpenModalConfirm"
      @ok="cancelSubscription"
      @cancel="closeModalConfirm"
      okText="Confirm"
      okClass="btn btn-danger"
      cancelText="Cancel"
    >
      <span slot="title" class="text-danger">Cancel Subscription</span>
      <p>Are you sure you want to cancel subscription?</p>
    </vuestic-modal>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  components: {  },
  data () {
    return {
      loading: false,
      loadingDiscount: false,
      processing: false,
      couponProcessing: false,
      plan: undefined,
      isOpenModalConfirm: false,
      couponCode: '',
      discount: undefined,
    }
  },

  mounted () {
    this.loading = true
    this.$store.dispatch('subscription/getPlansByType', 'zapier')
      .then((res) => {
        this.loading = false
        if (res && res.length)
          this.plan = res[0]
      })
      .catch((err) => {
        this.loading = false
        this.plan = undefined
      })

    this.loadingDiscount = true
    this.$store.dispatch('auth/getDiscounts', 'zapier')
      .then((res) => {
        this.loadingDiscount = false
        this.discount = res.length ? res[0]: undefined;
      })
      .catch((err) => {
        this.loadingDiscount = false
      })
  },

  methods: {
    onApplyCoupon() {
      this.couponProcessing = true
      const param =  {
        code: this.couponCode,
        type: 'zapier',
      }

      if (this.subscription && this.subscription.active)
      {
        this.$store.dispatch('subscription/applyCoupon', param)
        .then((coupon) => {
          this.couponCode = ''
          this.couponProcessing = false
          if (coupon)
          {
            this.discount = {
              id: 'new-applied',
              coupon
            }
          }
        })
        .catch((err) => {
          this.couponProcessing = false
        })
      } else {
        this.$store.dispatch('subscription/getCoupon', param)
        .then((coupon) => {
          this.couponCode = ''
          this.couponProcessing = false
          if (coupon)
          {
            this.discount = {
              id: undefined,
              coupon
            }
          }
        })
        .catch((err) => {
          this.couponProcessing = false
        })
      }
    },

    closeModalConfirm () {
      this.isOpenModalConfirm = false
    },

    onCancel() {
      this.isOpenModalConfirm = true
    },

    onSubmit() {
      this.processing = true

      const coupon = this.discount ? this.discount.coupon.id: undefined

      this.$store.dispatch('subscription/subscriptionPlan', {
        plan: this.plan.id,
        type: 'zapier',
        coupon
      }).then((res) => {
        this.couponCode = ''
        this.processing = false
        if (coupon)
        {
          this.discount.id = 'new-applied'
        }
      })
      .catch((err) => {
        this.processing = false
      })

    },

    cancelSubscription() {
      this.closeModalConfirm();
      this.processing = true
      this.$store.dispatch('subscription/cancelSubscription', {
        userId: this.user.id,
        type: 'zapier',
      }).then(({ user }) => {
        this.processing = false
        if (!user.subscriptions.zapier.grace_plan)
        {
          this.discount = undefined
        }
      })
      .catch((err) => {
        this.processing = false
      })
    },

    onResume() {
      this.processing = true
      this.$store.dispatch('subscription/resumeSubscription', {
        userId: this.user.id,
        type: 'zapier',
      }).then((res) => {
        this.processing = false
      })
      .catch((err) => {
        this.processing = false
      })
    }
  },

  computed: {
    ...mapGetters('auth', {
      subscription: 'zapierSubscription'
    }),
    zapInviteURL() {
      return process.env.VUE_APP_ZAP_PRIVATE_LINK
    },
    user () {
      return this.$store.state.auth.user
    },
    discountPrice() {
      return (this.plan.amount - this.discount.coupon.amount_off / 100).toFixed(2)
    },
    showDiscountInput() {
      return (!this.discount || !this.discount.id) && (!this.subscription || !this.subscription.grace_plan)
    }
  },
}
</script>
